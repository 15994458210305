import { ref } from "vue";
import { useStore } from 'vuex';
import axios from "axios";
import router from "@/router";

export default function useAuth() {
    
    const store = useStore();

    const status = ref(Boolean);
    const error = ref('');
    const message = ref('');

    const auth = ref([]);
    const user = ref([]);

    const authLogin = async (data) => {
        
        error.value = "";
        status.value = true;
        
        try {
            let response = await axios.post('/login', data);
            store.dispatch('auth/setUserToken', response.data.token);
            store.dispatch('auth/setUser', response.data.user);
            await router.push({name:'app.default.dashboard'});
        } catch (e) {
            status.value = false;
            if(e.response.status === 422){
                for(const key in e.response.data.errors){
                    error.value += e.response.data.errors[key][0] + " <br/>";
                }
            }
            if(e.response.status === 400){
                error.value += e.response.data.message + " <br/>";
            }
        }
    };

    return {
        status,
        error,
        message,

        auth,
        user,
        authLogin
    }
}