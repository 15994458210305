<template>
  <!-- Begin page content -->
  <div class="container">
    <div class="page-header"><h2>Login</h2></div>

    <!-- Begin Form content -->
    <div class="well bs-component">
      <form class="form-horizontal" form @submit.prevent="login">
        <fieldset>
          <div class="form-group">
            <label for="inputDsUsuario" class="col-lg-2 control-label">Usuário</label>
            <div class="col-lg-10">
              <input
                type="text"
                v-model="form.email"
                name="email"
                id="inputDsUsuario"
                class="form-control" 
                placeholder="Usuario"
                required
                autofocus
              />
            </div>
          </div>
          <div class="form-group">
            <label for="inputDsPassword" class="col-lg-2 control-label">Senha</label>
            <div class="col-lg-10">
              <input
                type="password"
                v-model="form.password"
                required
                name="password"
                id="inputDsPassword"
                placeholder="Senha"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="col-lg-10 col-lg-offset-2">
              <button type="submit" class="btn btn-primary">Entrar</button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <!--/. End Form content -->

  </div>
  <!--/.End page content -->
</template>

<script>
import useCommon from "@/common";
import useAuth from "@/composables/Auth";
import { onMounted, reactive } from "vue";

export default {
  setup() {
    const { getToast } = useCommon();
    const { status, error, authLogin } = useAuth();

    //
    const form = reactive({
      email: "",//"admin@ecolatas.com.br",
      password: ""//"P@ssw0rd",
    });

    const open = () => {
      getToast.open({
        message: error.value,
        type: "error",
      });
    };

    const login = async () => {
      await authLogin({ ...form });
      if (!status.value) {
        open();
      }
    };

    onMounted(function () {
      //
    });

    return {
      form,
      error,
      login,
    };
  },
};
</script>
